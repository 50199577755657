<template>
    <form class="tenant-form" @submit="onSubmit">
        <div class="tenant-form__row">
            <div class="tenant-form__column">
                <Input
                    id="tenant-name"
                    v-model="name"
                    type="text"
                    label="Name"
                    name="tenant-name"
                    required
                />
            </div>
        </div>
        <fieldset>
            <legend>Chat widget</legend>
            <div class="tenant-form__row">
                <div class="tenant-form__column">
                    <Input
                        id="tenant-app-key"
                        v-model="appKey"
                        type="text"
                        label="App key"
                        name="tenant-app-key"
                    />
                </div>
                <div class="tenant-form__column">
                    <Input
                        id="tenant-app-secret"
                        v-model="appSecret"
                        type="text"
                        label="App secret"
                        name="tenant-app-secret"
                    />
                </div>
            </div>
            <div class="tenant-form__row">
                <div class="tenant-form__column">
                    <Tags
                        id="tenant-chat-widget-allow-origin"
                        v-model="allowOrigins"
                        label="Allow origins"
                        placeholder=""
                    />
                </div>
            </div>
        </fieldset>

        <fieldset>
            <legend>Channels</legend>
            <div class="m-b-2">
                <TextButton type="button" @click.prevent="showChannelsOverlay = true">
                    Edit channels list ({{ channels.length }} channel<template
                        v-if="channels.length > 1"
                        >s</template
                    >)
                </TextButton>
                <ErrorMessage v-if="showEmptyChannelsError" size="small" class="error-message">
                    Atleast one channel is required to be added.
                </ErrorMessage>
            </div>
            <FormOverlay
                title="Channels"
                description="Type to search Channels"
                :show-overlay="showChannelsOverlay"
                @close="channelsOverlayOnClose"
                @confirm="channelsOverlayOnClose"
                @afterEnter="channelOverlayOnAfterEnter"
            >
                <div>
                    <AutoComplete
                        ref="channelSearchInput"
                        class="m-b-2"
                        :items="autoCompleteItems"
                        label="Channels"
                        placeholder="Type to find a Channel"
                        :is-async="true"
                        :value="channel"
                        @input="onInput"
                        @focus="onSearch"
                        @query="onSearch"
                    >
                        <template v-slot:default="{ result, resultIndex }">
                            <div v-if="filteredChannels[resultIndex]" class="channel-result">
                                {{ filteredChannels[resultIndex].text }}

                                <Badge :type="filteredChannels[resultIndex].type">
                                    {{ filteredChannels[resultIndex].label }}
                                </Badge>
                            </div>
                        </template>
                    </AutoComplete>

                    <div class="channel-list">
                        <AttributeButton
                            v-for="(item, index) in channels"
                            :key="index"
                            class="channel-list__item"
                            @remove="removeChannel($event, index)"
                        >
                            {{ item.name }}
                        </AttributeButton>
                    </div>
                </div>
            </FormOverlay>
        </fieldset>

        <h2 class="bold m-b-1">Applications</h2>
        <Datatable
            class="applications-table m-b-3"
            :data="{ items: applications }"
            :columns="applicationsTableColumns"
            @rowClick="applicationsRowOnClick"
        >
            <template v-slot:action="slotProps">
                <div class="align-right">
                    <Icon
                        class="applications-table-open-icon"
                        :class="{
                            'applications-table-open-icon--opened': slotProps.item.hasDetails,
                        }"
                        name="arrow-down"
                    />
                </div>
            </template>
            <template v-slot:signupOptions="{ item }">
                {{ getTriggerText(item.signupOptions.trigger) }}
            </template>
            <template v-slot:details="{ item, index }">
                <ApplicationFormFields
                    :data="item"
                    @onChange="(appData) => applicationOnChange(appData, index)"
                />
            </template>
        </Datatable>

        <Button has-border :is-loading="isLoading" type="submit">Save</Button>
    </form>
</template>

<script>
import debounce from 'lodash/debounce';
import { Input, Tags, AutoComplete } from '@/components/forms';
import Button from '@/components/ui/Button';
import TextButton from '@/components/ui/TextButton';
import AttributeButton from '@/components/ui/AttributeButton';
import FormOverlay from '@/components/ui/FormOverlay';
import Icon from '@/components/ui/Icon';
import Datatable from '@/components/Datatable';
import ApplicationFormFields from '@/components/ApplicationFormFields';
import { LoadingFlag } from '@/store/enums/loadingIds.enum.ts';
import tenantSignupTriggerOptions from '@/json/tenantSignupTriggerOptions';
import Badge from '@/components/ui/Badge';
import ErrorMessage from '@/components/ui/ErrorMessage';

export default {
    name: 'TenantForm',
    components: {
        Input,
        Tags,
        AutoComplete,
        Button,
        TextButton,
        AttributeButton,
        FormOverlay,
        Icon,
        Datatable,
        ApplicationFormFields,
        Badge,
        ErrorMessage,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        channelSearchingIsLoading: {
            type: Boolean,
            default: false,
        },
        channelSearchOptions: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            name: this.data.name,
            appKey: this.data.appKey,
            appSecret: this.data.appSecret,
            allowOrigins:
                (this.data.allowOrigins &&
                    this.data.allowOrigins.length &&
                    this.data.allowOrigins.map((item) => ({ name: item }))) ||
                [],
            channels: this.data.channels || [],
            applications: this.data.applications.map((item) => ({
                ...item,
                maxFreeConvos: item.maxFreeConvos.toString(),
            })),
            channel: '',
            showChannelsOverlay: false,
            applicationsTableColumns: [
                {
                    value: 'name',
                    header: 'Name',
                    type: 'text',
                    width: '40%',
                },
                {
                    value: 'signupOptions',
                    header: 'Auth',
                    type: 'slot',
                    width: '20%',
                },
                {
                    value: 'registrationType',
                    header: 'Registration',
                    type: 'text',
                    width: '20%',
                },
                {
                    value: 'action',
                    header: '',
                    type: 'slot',
                    width: '20%',
                    class: 'text-left',
                },
            ],
            showEmptyChannelsError: false,
        };
    },
    computed: {
        isLoading() {
            return this.$wait.is(LoadingFlag.TenantSave);
        },
        autoCompleteItems() {
            return this.filteredChannels.map((channel) => channel.code);
        },
        filteredChannels() {
            const addedChannelCodes = this.channels.map((item) => item.code);
            return this.channelSearchOptions.reduce((list, item) => {
                if (!addedChannelCodes.includes(item.code)) {
                    list.push(item);
                }
                return list;
            }, []);
        },
    },
    methods: {
        onSubmit(event) {
            event.preventDefault();
            if (!this.channels.length) {
                this.showEmptyChannelsError = true;
                return;
            }
            this.save();
        },
        save: debounce(function () {
            const data = {
                ...this.data,
                name: this.name,
                appKey: this.appKey,
                appSecret: this.appSecret,
                allowOrigins: this.allowOrigins.map((item) => item.name),
                channels: this.channels.map((item) => ({ code: item.code, name: item.name })),
                applications: this.applications,
            };

            this.$emit('save', data);
        }, 300),
        generateId(name, id) {
            return `${name}-${id}`;
        },
        onSearch(query) {
            this.channel = query;
            this.$emit('searchChannels', query);
        },
        onInput(item, itemIndex, channelIndex) {
            this.channel = '';
            const channel = this.filteredChannels[channelIndex];
            if (channel) {
                this.showEmptyChannelsError = false;
                this.channels.push({ code: channel.code, name: channel.text });
            }
        },
        removeChannel(event, index) {
            event.preventDefault();
            this.channels.splice(index, 1);
        },
        channelOverlayOnAfterEnter() {
            this.$refs.channelSearchInput.focus();
        },
        channelsOverlayOnClose() {
            this.showChannelsOverlay = false;
            this.$emit('clearChannelSearchOptions');
        },
        applicationsRowOnClick(event, item) {
            const appIndex = this.applications.findIndex((app) => app.id === item.id);
            const applications = [...this.applications];
            applications[appIndex].hasDetails = !applications[appIndex].hasDetails;
            this.applications = applications;
        },
        applicationOnChange(data, index) {
            const applications = [...this.applications];
            applications[index] = data;
            this.applications = applications;
        },
        getTriggerText(trigger) {
            return tenantSignupTriggerOptions.find((item) => item.id === trigger)?.value;
        },
    },
};
</script>

<style lang="scss" scoped>
.tenant-form {
    @include media-query(medium) {
        &__row {
            display: flex;
        }

        &__column {
            width: 50%;
            padding-right: 10px;
            padding-left: 10px;

            &:first-child {
                padding-left: 0;
            }

            &:last-child:not(:nth-child(1)) {
                padding-right: 0;
            }
        }
    }

    legend {
        font-weight: $font-weight-bold;
        margin-bottom: $default-vertical-space * 1.5;
    }

    fieldset {
        margin-bottom: $default-vertical-space;
    }
}

.channel-list {
    position: relative;
    top: -5px;
    margin-left: -5px;

    &__item {
        margin: 5px;
        cursor: default;
    }
}

.applications-table-open-icon {
    margin-right: 10px;
    font-size: 12px;
    color: $primary-color;

    &--opened {
        transform: rotate(180deg);
    }
}

.channel-result {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.error-message {
    padding-left: 4px;
}
</style>

<style lang="scss">
.applications-table {
    tbody {
        tr {
            cursor: pointer;

            &.row {
                &--has-details {
                    background: $table-row-hover;
                }

                &--details {
                    cursor: default;

                    &,
                    &:hover {
                        background: lighten($table-row-hover, 3%);
                    }
                }
            }
        }
    }
}
</style>
