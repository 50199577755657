<template>
    <div class="page-layout-container">
        <div class="page-layout-main">
            <FullPageLoadingSpinner :show="pageIsLoading" />

            <PageHeading :breadcrumbs="breadcrumbs" title="Edit Tenant" />

            <template v-if="!pageIsLoading">
                <TenantForm
                    v-if="tenant"
                    :data="tenant"
                    :channel-searching-is-loading="searchingIsLoading"
                    :channel-search-options="contentOptions"
                    @searchChannels="searchChannels"
                    @save="saveTenant"
                    @clearChannelSearchOptions="clearSearchResultOptions"
                />
            </template>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import FullPageLoadingSpinner from '@/components/ui/FullPageLoadingSpinner';
import TenantForm from '@/components/TenantForm';
import { LoadingFlag } from '@/store/enums/loadingIds.enum.ts';
import { Breadcrumb } from '@/store/models/breadcrumb.dto.ts';
import PageHeading from '@/components/ui/PageHeading';

export default {
    name: 'Tenant',
    components: {
        FullPageLoadingSpinner,
        TenantForm,
        PageHeading,
    },
    data() {
        return {
            breadcrumbs: [],
            tableColumns: [
                {
                    value: 'name',
                    header: 'Name',
                    type: 'text',
                    width: '20%',
                },
                {
                    value: 'code',
                    header: 'Code',
                    type: 'text',
                    width: '65%',
                },
                {
                    value: 'action',
                    header: '',
                    type: 'slot',
                    width: '15%',
                    class: 'text-left',
                },
            ],
            openedTenants: {},
            LoadingFlag,
            tenantCode: this.$route.params.tenantCode,
        };
    },
    computed: {
        ...mapState('tenant', ['searchingIsLoading', 'contentOptions', 'tenants', 'tenant']),
        pageIsLoading() {
            return this.$wait.is(LoadingFlag.TenantGet);
        },
    },
    watch: {
        tenant(newVal) {
            if (newVal) {
                this.setBreadcrumbs();
            }
        },
    },
    mounted() {
        this.getTenant(this.tenantCode);
    },
    created() {
        this.setBreadcrumbs();
    },
    beforeDestroy() {
        this.clearTenant();
    },
    methods: {
        ...mapActions('tenant', [
            'searchContent',
            'clearSearchResultOptions',
            'getTenant',
            'saveTenant',
            'clearTenant',
        ]),
        searchChannels(query) {
            this.searchContent({ type: 'channels', query, includeInactive: true });
        },
        setBreadcrumbs() {
            const breadcrumbs = [];
            const tenantsLink = { name: 'tenant-list' };
            breadcrumbs.push(new Breadcrumb('tenants', tenantsLink));
            if (this.tenant) {
                breadcrumbs.push(new Breadcrumb(this.tenant.name));
            }
            this.breadcrumbs = breadcrumbs;
        },
    },
};
</script>
