<template>
    <div class="application-form-wrapper">
        <div class="tenant-form__row">
            <div class="tenant-form__column">
                <Input
                    :id="generateId('app-code', data.code)"
                    :value="data.code"
                    :name="generateId('app-code', data.code)"
                    type="text"
                    label="App code"
                    :disabled="true"
                />
            </div>

            <div class="tenant-form__column">
                <Input
                    :id="generateId('app-name', data.id)"
                    v-model="name"
                    :name="generateId('app-name', data.id)"
                    type="text"
                    label="Name"
                    required
                    @changed="onChange"
                />
            </div>
        </div>

        <fieldset>
            <legend>Authentication</legend>
            <div class="tenant-form__row">
                <div class="tenant-form__column">
                    <Select
                        :id="generateId('app-signup-trigger', data.id)"
                        v-model="signupTrigger"
                        :name="generateId('app-signup-trigger', data.id)"
                        label="Trigger"
                        :options="$options.tenantSignupTriggerOptions"
                        @changed="onChange"
                    />
                </div>
                <div class="tenant-form__column">
                    <Toggle
                        :id="generateId('app-skip-auth-for-known-users', data.id)"
                        v-model="skipAuthForKnownUsers"
                        :name="generateId('app-skip-auth-for-known-users', data.id)"
                        class="m-b-2"
                        label="Skip authentication for known users"
                        :disabled="!authEnabled"
                        @changed="onChange"
                    />
                </div>
            </div>

            <div class="tenant-form__row">
                <div class="tenant-form__column">
                    <Toggle
                        :id="generateId('app-allow-no-signup', data.id)"
                        v-model="allowNoSignup"
                        :name="generateId('app-allow-no-signup', data.id)"
                        class="m-b-2"
                        label="Allow noSignup option"
                        @changed="onChange"
                    />
                </div>
                <div class="tenant-form__column">
                    <Toggle
                        :id="generateId('app-fingerprint-tracking-enabled', data.id)"
                        v-model="fingerprintTrackingEnabled"
                        :name="generateId('app-fingerprint-tracking-enabled', data.id)"
                        class="m-b-2"
                        label="Fingerprint tracking"
                        @changed="onChange"
                    />
                </div>
            </div>

            <div class="tenant-form__row">
                <div class="tenant-form__column">
                    <Select
                        :id="generateId('app-signup-type', data.id)"
                        v-model="signupType"
                        :name="generateId('app-signup-type', data.id)"
                        label="Signup type"
                        :options="$options.signupTypeOptions"
                        :disabled="!authEnabled"
                        @changed="onChange"
                    />
                </div>
                <div class="tenant-form__column">
                    <Input
                        :id="generateId('app-max-free-convos', data.id)"
                        v-model="maxFreeConvos"
                        :name="generateId('app-max-free-convos', data.id)"
                        type="number"
                        label="Max free convos"
                        :disabled="!authEnabled"
                        @changed="onChange"
                    />
                </div>
            </div>
        </fieldset>

        <fieldset v-if="isSignup">
            <legend>Signup</legend>
            <div>
                <div class="tenant-form__row">
                    <div class="tenant-form__column">
                        <Input
                            :id="generateId('app-signup-message', data.id)"
                            v-model="signupMessage"
                            :name="generateId('app-signup-message', data.id)"
                            type="textarea"
                            label="Signup message"
                            @changed="onChange"
                        />
                    </div>
                    <div class="tenant-form__column">
                        <Toggle
                            :id="generateId('app-signup-default-subscribe-selection', data.id)"
                            v-model="signupDefaultSubscribeSelection"
                            :name="generateId('app-signup-default-subscribe-selection', data.id)"
                            label="Email subscription selected by default"
                            @changed="onChange"
                        />
                    </div>
                </div>
            </div>
        </fieldset>

        <fieldset v-else-if="isSubscribe">
            <legend>Subscribe</legend>
            <div class="tenant-form__row">
                <div class="tenant-form__column">
                    <Input
                        :id="generateId('app-subscribe-message', data.id)"
                        v-model="subscribeMessage"
                        :name="generateId('app-subscribe-message', data.id)"
                        type="text"
                        label="Subscribe message"
                        required
                        @changed="onChange"
                    />
                </div>
                <div class="tenant-form__column">
                    <Input
                        :id="generateId('app-subscribe-message-button', data.id)"
                        v-model="subscribeButtonText"
                        :name="generateId('app-subscribe-message-button', data.id)"
                        type="text"
                        label="Subscribe button text"
                        required
                        @changed="onChange"
                    />
                </div>
            </div>
            <div class="tenant-form__row">
                <div class="tenant-form__column">
                    <Input
                        :id="generateId('app-conditions-message', data.id)"
                        v-model="conditionsText"
                        :name="generateId('app-conditions-message', data.id)"
                        type="text"
                        label="Terms and Conditions text"
                        required
                        @changed="onChange"
                    />
                </div>
                <div class="tenant-form__column">
                    <Input
                        :id="generateId('app-subscribe-input-button', data.id)"
                        v-model="inputText"
                        :name="generateId('app-subscribe-input-button', data.id)"
                        type="text"
                        label="Email input text"
                        required
                        @changed="onChange"
                    />
                </div>
            </div>
        </fieldset>

        <fieldset>
            <legend>Chat</legend>
            <div v-if="$options.tenantLinkTargetEnabled" class="tenant-form__row">
                <div class="tenant-form__column">
                    <Select
                        :id="generateId('app-link-target', data.id)"
                        v-model="linkTarget"
                        :name="generateId('app-link-target', data.id)"
                        label="Links behaviour"
                        :options="$options.tenantLinkTargetOptions"
                        @changed="onChange"
                    />
                </div>
            </div>
        </fieldset>
    </div>
</template>

<script>
import Input from '@/components/forms/Input';
import Select from '@/components/forms/Select';
import Toggle from '@/components/forms/Toggle';
import tenantSignupTypeOptions from '@/json/tenantSignupTypeOptions';
import tenantLinkTargetOptions from '@/json/tenantLinkTargetOptions';
import tenantSignupTriggerOptions from '@/json/tenantSignupTriggerOptions';
import { TenantSignupTrigger } from '@/store/enums/tenantSignupTrigger.enum';
import config from '@/config';

export default {
    name: 'ApplicationFormFields',
    components: {
        Input,
        Select,
        Toggle,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        channelSearchingIsLoading: {
            type: Boolean,
            default: false,
        },
        channelSearchOptions: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            name: this.data.name,
            signupType: this.data.signupType,
            skipAuthForKnownUsers: !!this.data.skipAuthForKnownUsers,
            allowNoSignup: !!this.data.allowNoSignup,
            fingerprintTrackingEnabled: !!this.data.fingerprintTrackingEnabled,
            maxFreeConvos: this.data.maxFreeConvos,
            registrationType: this.data.registrationType,
            subscribeMessage: this.data.registrationOptions.subscribeMessage,
            subscribeButtonText: this.data.registrationOptions.subscribeButtonText,
            conditionsText: this.data.registrationOptions.conditionsText,
            inputText: this.data.registrationOptions.inputText,
            linkTarget: this.data.linkOptions.target,
            signupMessage: this.data.signupOptions.message,
            signupTrigger: this.data.signupOptions.trigger,
            signupDefaultSubscribeSelection: this.data.signupOptions.defaultSubscribeSelection,
        };
    },
    computed: {
        authEnabled() {
            return [
                TenantSignupTrigger.SignupChatStart,
                TenantSignupTrigger.SubscribeChatStart,
            ].includes(this.signupTrigger);
        },
        isSignup() {
            return [
                TenantSignupTrigger.SignupChatStart,
                TenantSignupTrigger.SignupLoadComments,
            ].includes(this.signupTrigger);
        },
        isSubscribe() {
            return this.signupTrigger === TenantSignupTrigger.SubscribeChatStart;
        },
    },
    created() {
        this.$options.signupTypeOptions = tenantSignupTypeOptions;
        this.$options.tenantLinkTargetOptions = tenantLinkTargetOptions;
        this.$options.tenantSignupTriggerOptions = tenantSignupTriggerOptions;
        this.$options.tenantLinkTargetEnabled = config.featureToggle.tenantLinkTargetEnabled;
    },
    methods: {
        onChange() {
            const data = {
                ...this.data,
                name: this.name,
                signupType: this.signupType,
                skipAuthForKnownUsers: this.skipAuthForKnownUsers,
                allowNoSignup: this.allowNoSignup,
                fingerprintTrackingEnabled: this.fingerprintTrackingEnabled,
                maxFreeConvos: this.maxFreeConvos,
                registrationType: this.registrationType,
                registrationOptions: {
                    subscribeMessage: this.subscribeMessage,
                    subscribeButtonText: this.subscribeButtonText,
                    conditionsText: this.conditionsText,
                    inputText: this.inputText,
                },
                signupOptions: {
                    message: this.signupMessage,
                    trigger: this.signupTrigger,
                    defaultSubscribeSelection: this.signupDefaultSubscribeSelection,
                },
                linkOptions: {
                    target: this.linkTarget,
                },
            };

            this.$emit('onChange', data);
        },
        generateId(name, id) {
            return `${name}-${id}`;
        },
        onRegistrationTypeChange(value) {
            this.registrationType = value;
        },
    },
};
</script>

<style lang="scss" scoped>
.tenant-form {
    @include media-query(medium) {
        &__row {
            display: flex;
        }

        &__column {
            width: 50%;
            padding-right: 10px;
            padding-left: 10px;

            &:first-child {
                padding-left: 0;
            }

            &:last-child:not(:nth-child(1)) {
                padding-right: 0;
            }
        }
    }

    legend {
        font-weight: $font-weight-bold;
        margin-bottom: $default-vertical-space * 1.5;
    }

    fieldset {
        margin-bottom: $default-vertical-space;
    }
}

.application-form-wrapper {
    padding: 20px;
}

.registration-options {
    max-width: 200px;
}
</style>
